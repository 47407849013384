import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  template: `
      <app-header></app-header>
      <main class="container">
          <router-outlet></router-outlet>
      </main>
  `,
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'hm24-pay';
}
