import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-header',
    template: `
        <div class="main">
            <nav class="navbar d-flex flex-row justify-content-between">
                <div>
                    <a href="/" class="navbar-brand">
                        <img src="../../../../assets/images/logo-t24.png" alt="" class="d-inline-block logo">
                    </a>
                </div>
            </nav>
        </div>
    `,
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
